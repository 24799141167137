<script>
export default {
  props: {
    name: String,
    attr: Object,
    checked: String,
    value: [Boolean,Number],
    validate: {default:""},
    disabled: Boolean,
  },
  data(){
    return {
      input: this.value,
    }
  },
  methods:{
    onClick(){
      this.$emit('input', !this.input)
      setTimeout(()=>$(this.$el).find("input").valid(), 10)
    }
  },
  watch:{
    input(v){ this.$emit("input", v); },
    value(v){ this.input = v; },
  }
}
</script>

<template>
  <div class="inline checkbox checkbox-info" @click="onClick">
    <input type="checkbox" v-model="input" :name="name" v-bind="attr">
    <label><slot></slot></label>
  </div>
</template>